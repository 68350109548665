/* The variables inside this document can be overwritten by custom themes. */

:root {
  /* -- General -- */
  --page-background-color: #fff;
  --info-background-color: #fff2dc;
  --max-page-width: 600px;
  --main-layout-padding-sides: 1.5rem;

  /* -- Font Definitions -- */
  --font-family: Arial, Helvetica, sans-serif;
  --font-size-very-small: 0.75rem;
  --font-size-small: 0.85rem;
  --font-size: 1rem;
  --font-size-large: 1.2rem;
  --font-size-very-large: 1.35rem;

  /* -- Border -- */
  --border-color: #005EA8;
  --border-width: 1px;
  --border-radius: 0px;
  --focus-outline-color: #3065d9;

  /* -- Z-Index -- */
  --popover-zindex: 2;

  /* -- CUSTOMIZABLE -- */
  --primary-text-color: #005ea8;
  --secondary-text-color: #ffffff;
  --tertiary-text-color: #ff6600;
  --primary-background-color: #E1EEF5;
  --secondary-background-color: #00529f;
  --tertiary-background-color: #ececec;
  --active-ui-color: #ff6600;
  --active-ui-text-color: #ffffff;
  --inactive-ui-color: #ebebeb;
  --inactive-ui-text-color: #a6a6a6;
  --error-color: #D60000;
}
