/* You can add global styles to this file, and also import other style files */
@import 'normalize.css';
@import 'variables.scss';

html {
  max-width: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background-color: var(--page-background-color);
  font-family: var(--font-family);
  font-size: var(--font-size);
  color: var(--primary-text-color);
}

main {
  //padding: 2rem 1.5rem;
  margin-inline: auto;
}

p, span {
  hyphens: auto;
}

input:hover,
input:active,
input:focus,
a:focus,
button:enabled:hover,
button:enabled:focus,
button:enabled:active {
  outline: 2px solid var(--focus-outline-color);
}

input[type='text'],
input[type='number'],
input[type='password'],
input[type='email'] {
  width: 100%;
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  padding: 0.5rem;

  &.error,
  &:user-invalid,
  &.ng-invalid.ng-dirty,
  &.ng-invalid.ng-touched {
    border-color: var(--error-color);
  }
}

.icon {
  width: 64px;
  height: 64px;
}

.block--centered {
  display: block;
  text-align: center;
}

.block--flex {
  display: flex;
}

.block--colored {
  background-color: var(--primary-background-color);
  color: var(--primary-text-color);
  margin: 1rem 0;
  padding: 0.5rem 1.5rem;
}

.text--centered {
  text-align: center;
}

.text--bold {
  font-weight: bold;
}

.text--very-small {
  font-size: var(--font-size-very-small);
}

.text--small {
  font-size: var(--font-size-small);
}

.text--medium {
  font-size: var(--font-size);
}

.text--large {
  font-size: var(--font-size-large);
}

.text--very-large {
  font-size: var(--font-size-very-large);
}

.no-margin {
  margin: 0;
}

.error-message {
  color: var(--error-color);
  font-size: var(--font-size-very-small);
  font-weight: bold;
}

.visually-hidden {
  /* https://www.tpgi.com/the-anatomy-of-visually-hidden/ */
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.text--highlighted {
  color: var(--tertiary-text-color);
}

.img--mobile-compatible {
  max-width: 300px;
}

h2 {
  font-size: 1em;
}

@media only screen and (max-width: 400px) {
  .img--mobile-compatible {
    max-width: 150px;
  }

  h1 {
    font-size: clamp(1.6rem, 10vw, 1.8rem);
  }

  h2 {
    font-size: clamp(0.8rem, 5vw, 1.2rem);
  }

  .block--colored {
    margin: 1rem calc(-1 * var(--main-layout-padding-sides));
  }
}
